<template>
    <div class="qingzhou">
        <!-- 背景 -->
        <div class="index_bg">
            <video
                poster="@/assets/imgs/img-wechat-home-bg.png"
                preload="auto"
                x5-video-player-fullscreen="true"
                x5-playsinline
                playsinline
                webkit-playsinline
                autoplay
                loop
                muted
            >
                <source
                    src="https://cloud-static.skiffenergy.com/20230404/login_bg.mp4"
                    type="video/mp4"
                />
            </video>
            <div class="mask">
                <the-nav></the-nav>
                <div class="index_bg_bottom">
                    <h1>轻舟能科</h1>
                    <h6>储能信息技术专家</h6>
                </div>
            </div>
        </div>
        <!-- 1 -->
        <div class="qingzhou_box bg_gray" style="padding-bottom: 0">
            <div class="qingzhou_box_con">
                <div class="img"></div>
                <div class="absolute">
                    <div class="common_title">
                        <div class="cn_title">领航储能数智化</div>
                    </div>
                    <div class="desc">
                        碳中和是全球能源变革的共同愿景，也是中国国家能源战略的远景目标，储能是其中不可或缺的组成部分。唯有电能实现大规模，灵活的存储，才能构建面向未来的能源互联网。
                        <br />
                        <br />
                        当前储能行业方兴未艾，面临电池安全，成本较高，行业数字化落后，运维粗放等种种问题，这些问题制约着储能行业的发展。轻舟科技希望通过信息技术的力量，帮助储能降本增效，卫护电站安全，更快地推动储能行业的全面市场化，早日实现碳中和的伟大目标。
                    </div>
                </div>
            </div>
        </div>
        <!-- 简介 -->
        <div class="qingzhou_box">
            <div class="qingzhou_box_con">
                <div class="common_title align_center justify_center">
                    <div class="cn_title">公司简介</div>
                    <div class="en_title">Company profile</div>
                </div>
                <img
                    class="people"
                    src="../../assets/mobile/img-m-aboutus-picture.png"
                    alt="合照"
                />
                <span class="introduction">
                    杭州轻舟科技有限公司成立于2016年，是一家专注于储能行业的物联网科技企业，基于行业独有的人工智能与物联网（AIoT）技术平台，为储能电站提供运维管理综合解决方案，覆盖能量管理，安全，运维，结算，金融等运营全流程。
                    <br />
                    <br />
                    公司创始团队毕业于浙江大学，曾各就职于百度、阿里等知名互联网公司，具有多年储能信息技术研发和实施服务经验。目前已服务30+电站，覆盖中国，德国，新加坡等国家，积累数据超10TB。
                    <br />
                    <br />
                    轻舟科技坚守让储能更安全、更长效，更智能的使命，通过信息技术赋能，助力储能行业数字化智能化升级，为碳中和目标的早日实现贡献一份力量。
                </span>
            </div>
        </div>
        <!-- 联系 -->
        <div class="qingzhou_box bg_gray">
            <div class="qingzhou_box_con">
                <div class="common_title">
                    <div class="cn_title">联系我们</div>
                    <div class="en_title">contact us</div>
                </div>
                <div class="qingzhou_box_con_map">
                    <div class="contact">
                        <svg class="svg_logo" aria-hidden="true">
                            <use xlink:href="#logo-skiff-black"></use>
                        </svg>
                        <ul>
                            <li>
                                <svg class="svg_footer" aria-hidden="true">
                                    <use xlink:href="#phone-fill"></use>
                                </svg>
                                <span>15867118666 张经理</span>
                            </li>
                            <li>
                                <svg class="svg_footer" aria-hidden="true">
                                    <use xlink:href="#mail-fill"></use>
                                </svg>
                                <span>info@skiffenergy.com</span>
                            </li>
                            <li>
                                <svg class="svg_footer" aria-hidden="true">
                                    <use xlink:href="#location-fill"></use>
                                </svg>
                                <span>
                                    杭州市余杭区仓前街道鼎创财富中心B2 8层 801
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- footer -->
        <the-footer></the-footer>
    </div>
</template>

<script>
export default {
    name: "qingzhou",
};
</script>

<style lang="scss">
.qingzhou {
    .bg_gray {
        background-color: #f4f5f7;
    }
    .index_bg {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        video {
            width: 100%;
            height: 100vh;
            display: block;
            object-fit: cover;
        }
        .mask {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.35);
            display: flex;
            flex-direction: column;
        }
        &_bottom {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            h1 {
                text-align: center;
                font-size: px2rem(30);
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
                color: #ffffff;
            }
            h6 {
                font-size: px2rem(12);
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                text-align: center;
                color: rgba(255, 255, 255, 0.65);
                line-height: 2;
                letter-spacing: px2rem(16);
                margin-top: px2rem(16);
            }
        }
    }
    &_box {
        padding: px2rem(40) px2rem(24);
        .common_title {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-bottom: px2rem(24);
            .cn_title {
                font-size: px2rem(20);
                font-family: PingFangSC, PingFangSC-Semibold;
                font-weight: 600;
                color: rgba(0, 0, 0, 0.85);
            }
            .en_title {
                font-size: px2rem(14);
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.25);
                text-transform: uppercase;
                margin-top: px2rem(4);
            }
            &::after {
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 2;
                content: "";
                display: block;
                width: 100%;
                height: px2rem(2);
                background-color: #e6e6e6;
            }
            &::before {
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 3;
                content: "";
                display: block;
                width: px2rem(77);
                height: px2rem(2);
                background-color: #00c699;
            }
        }
        .justify_center {
            justify-content: center;
        }
        .align_center {
            align-items: center;
            &::before {
                left: 50%;
                transform: translateX(-50%);
            }
            &::after {
                left: 50%;
                transform: translateX(-50%);
            }
        }
        &_con {
            display: flex;
            flex-direction: column;
            .img {
                position: relative;
                width: 100%;
                height: px2rem(245);
                background-image: url("../../assets/mobile/img-m-aboutus-sky.png");
                background-position: center;
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }
            .absolute {
                position: relative;
                top: px2rem(-64);
                margin: 0 px2rem(16);
                padding: px2rem(32) px2rem(24);
                background-color: #ffffff;
                border-radius: px2rem(2);
                .desc {
                    margin-top: px2rem(16);
                    font-size: px2rem(14);
                    font-family: PingFangSC, PingFangSC-Regular;
                    font-weight: 400;
                    text-align: left;
                    color: rgba(0, 0, 0, 0.65);
                    line-height: 2;
                }
            }
            img.people {
                margin: px2rem(24) auto;
            }
            .introduction {
                line-height: 2;
                font-size: px2rem(14);
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                text-align: left;
                color: rgba(0, 0, 0, 0.65);
            }
            &_map {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                width: 100%;
                height: px2rem(525);
                margin-top: px2rem(24);
                padding-bottom: px2rem(16);
                background-image: url("../../assets/mobile/img-m-aboutus-map.png");
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100% 100%;
                .contact {
                    display: flex;
                    flex-direction: column;
                    margin: 0 px2rem(16);
                    padding: px2rem(32) px2rem(24);
                    background-color: rgba(255, 255, 255, 0.85);
                    border: px2rem(2) solid rgba(255, 255, 255, 0.85);
                    border-radius: px2rem(4);
                    overflow: hidden;
                    .svg_logo {
                        width: px2rem(135);
                        height: px2rem(40);
                    }
                    .svg_footer {
                        width: px2rem(18);
                        height: px2rem(18);
                        fill: #00c699;
                        margin-right: px2rem(8);
                    }
                    ul {
                        margin-top: px2rem(34);
                        li {
                            display: flex;
                            margin-bottom: px2rem(16);
                            font-size: px2rem(12);
                            font-family: AlibabaPuHuiTi, AlibabaPuHuiTi-Regular;
                            font-weight: 400;
                            text-align: left;
                            color: rgba(0, 0, 0, 0.65);
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
